import Link from 'next/link'
import styled from 'styled-components'
import { PROJECT350_URL } from 'utils/constants'
import { Image } from 'antd'

const StyledBannerDesktop = styled(Image)`
  max-width: 100%;
  height: auto;
  width: 100%;
  cursor: pointer;
  margin: 0 !important;

  @media (max-width: 576px) {
    display: none;
  }
`

const StyledBannerMobile = styled(Image)`
  display: none;
  max-width: 100%;
  height: auto;
  width: 100%;
  cursor: pointer;

  @media (max-width: 576px) {
    display: block;
  }
`
export default function Index() {
  return (
    <Link href={PROJECT350_URL}>
      <div>
        <StyledBannerDesktop preview={false} src='/images/project-350/catalog-banner-lg.png' alt='Project 350 Catalog Banner' />

        <StyledBannerMobile preview={false} src='/images/project-350/catalog-banner-xs.png' alt='Project 350 Catalog Banner' />
      </div>
    </Link>
  )
}
